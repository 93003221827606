<template>
<div>
  <div class="page" :style="titleStatus == 1 || titleStatus == 2?{height: 'calc(100vh - 144px)'}:{height: 'calc(100vh - 91px)'}">
    <!-- <div class="create">
      <el-button plain type="normal" size="mini" @click="close">返回</el-button>
    </div> -->

    <!-- 添加 编辑 -->
    <div class="box1" v-if="titleStatus == 1 || titleStatus == 2">
      <div class="tableRight">
        <el-form
          ref="ruleForm"
          class="inline:false"
          label-position="left"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="用户分类" prop="userType">
            <el-radio-group v-model="form.userType" @change="getTemplate" style="width:800px">
              <el-radio
                v-for="item in userTypes"
                :key="item.id"
                style="margin-bottom:10px"
                :label="item.id.toString()"
              >
              {{item.name}}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="采集模板" prop="templateName" >
            {{form.templateName?form.templateName:'选择用户分类后自动展示'}}
          </el-form-item>
          <el-form-item label="安检用户"   prop="userIds">
            <div class="informFlex" style="width: 80%">
              <div  style="width: 30%;height:400px;overflow: auto">
                <el-tree
                    ref="dataTree"
                    :data="dataTree"
                    :props="defaultProps"
                    node-key="id"
                    show-checkbox
                    @node-click="handleNodeClick"
                    @check-change="handleCheckChange"
                    :default-checked-keys="checkedKeys"
                    :default-expanded-keys="expandedKeys"
                >
                </el-tree>
              </div>
              <div class="table-contain">
                <span>总户数：{{this.form.userIds.length}}</span>
                <el-table
                  :data="tableData"
                  style="width: 100%"
                  :border="true"
                  height="340"
                  :header-cell-style="{ backgroundColor: '#F9FAFE' }"
                >
                  <el-table-column prop="addrName" label="片区"> </el-table-column>
                  <el-table-column prop="userCount" label="用户数量" width="130"></el-table-column>
                </el-table>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="安检员" prop="inspectorIds"  style="margin-top:24px">
            <el-select
              v-model="form.inspectorIds"
              placeholder="请选择"
              style="width: 300px"
              multiple
              filterable
            >
              <el-option
                v-for="item in checkerList"
                :key="item.id"
                :label="item.realname"
                :value="parseInt(item.id)"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务时间" prop="startTime"  style="margin-top:24px">
            <el-date-picker
              v-model="times"
              type="daterange"
              range-separator="至"
              placeholder="请选择时间段"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              @change="setDate"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          
        </el-form>
      </div>
    </div>
    <!-- 查看 -->
    <div class="box1" v-else-if="titleStatus == 3">
      <el-form class="watchForm" label-width="100px">
        <el-form-item label="用户分类">{{form.userTypeName}}</el-form-item>
        <el-form-item label="采集模板">{{form.templateName}}</el-form-item>
        <el-form-item label="安检用户">
          <span>总户数：{{form.houseCount}}</span>
          <el-table
            :data="tableData"
            style="width: 100%"
            max-height="400"
            :border="true"
            :header-cell-style="{ backgroundColor: '#F9FAFE'}"
          >
            <el-table-column prop="addrName" label="片区" align="center">
            </el-table-column>
            <el-table-column label="用户数量" width="130" align="center">
              <template slot-scope="{ row }">
                <span style="color: #1082ff;cursor: pointer" @click="watchUser(row)">{{ row.userCount }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="安检员">{{form.inspectorNames}}</el-form-item>
        <el-form-item label="任务时间">{{form.startTime}}-{{form.endTime}}</el-form-item>
      </el-form>
    </div>
    <!-- 查看用户 -->
    <el-dialog
      :visible.sync="userDialog"
      :close-on-click-modal="false"
      @close = "closeUser"
      width="80%">
      <el-table
        :data="tableDataUser"
        style="width: 100%"
        :border="true"
        height='500'
        :header-cell-style="{ backgroundColor: '#F9FAFE' }"
      >
        <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
        <el-table-column prop="userName" label="用户名称" align="center"></el-table-column>
        <el-table-column prop="contact" label="联系人" align="center"></el-table-column>
        <el-table-column prop="userPhone" label="电话" align="center"></el-table-column>
        <el-table-column prop="userTypeName" label="用户分类" align="center"></el-table-column>
        <el-table-column prop="addrName" label="片区" align="center"></el-table-column>
        <el-table-column prop="address" label="地址" align="center"></el-table-column>
        <el-table-column prop="userIdnumber" label="身份证号" align="center"></el-table-column>
        <el-table-column prop="businessLicenseNumber" label="营业执照号" align="center"></el-table-column>
        <el-table-column prop="tableCount" label="表具" align="center"></el-table-column>
        <el-table-column prop="deptName" label="负责部门" align="center"></el-table-column>
        <el-table-column prop="" label="安检员" align="center">
          <template  slot-scope="{row}">
            <span>{{row.inspectorName}}</span>
            <!-- <div v-if="row.inspectorName">({{row.inspectorName}})</div> -->
          </template>
        </el-table-column>
        <el-table-column prop="date" label="启用状态" align="center">
          <template slot-scope="{row}">
              <el-tag type="success" v-if="row.status==1">已启用</el-tag>
              <el-tag v-if="row.status==2">停用安检</el-tag>
              <el-tag type="info" v-if="row.status==3">停用不安检</el-tag>
              <el-tag type="danger" v-if="row.status==4">已销户</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageBox">
        <el-pagination
          :current-page="search.current"
          :background="true"
          :page-sizes="[30, 50, 100]"
          :page-size="search.size"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="userDialog = false">关闭</el-button>
      </span>
    </el-dialog>
    </div>
    <!-- <el-form-item > -->
      <el-button v-if="titleStatus == 1 || titleStatus == 2" type="primary" style="margin-left:157px" @click="confirm">确 定</el-button>
    <!-- </el-form-item> -->
  </div>
</template>

<script>
import {dict_selectDictListByPid,aaumTask_showTempletByUserType,aaumTask_findUserTreeEcho,aaumTask_taskInsertOrUpdate,AaumArchive_inspectorDropDown,aaumTask_selectTaskById,aaumTask_findAddrByAaumUsers,aaumTask_findUsersByTaskIdAndAddrId} from '../../../RequestPort/securityTask/unResident'
export default {
  data() {
    return {
      search:{
        current:1,
        size: 100
      },
      total:0,
      form: {userIds:[],inspectorIds:[],startTime:'',templateName:'',userType:''},
      userTypes: [
        { name: "工业用户", id: 1 },
        { name: "福利用户", id: 2 },
        { name: "商业综合体", id: 3 },
        { name: "个体商户", id: 4 },
      ],
      checkerList: [],
      dataTree: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "name",
      },
      tableData: [
        // {
        //   date: "2016-05-02",
        //   name: "王小虎",
        //   address: "上海市普陀区金沙江路 1518 弄",
        // },
        // {
        //   date: "2016-05-04",
        //   name: "王小虎",
        //   address: "上海市普陀区金沙江路 1517 弄",
        // },
      ],
      tableDataUser:[],
      tableDataNum:{},
      rules: {
        userType:{ required: true, message: "请选择用户分类", trigger: "change" },
        userIds:{ required: true, message: "请选择安检用户", trigger: "change" },
        inspectorIds:{ required: true, message: "请选择安检员", trigger: "change" },
        templateName:{ required: true, message: "请先添加模板", trigger: "change" },
        startTime:{ required: true, message: "请选择任务时间", trigger: "change"}
      },
      times: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; //若是没有后面的-8.64e7就是不能够选择今天的
        },
      },
      userDialog: false,
      addrId:'',
      checkedKeys:[],
      expandedKeys:[],
      titleStatus:1
    };
  },
  // props: ["titleStatus"],
  mounted() {
    
    
    // if(this.$route.query.id) {
      if(this.$route.query.titleStatus) {
      this.titleStatus = this.$route.query.titleStatus
    }
      this.getDesc(this.$route.query.id)
    // }
    this.getOption()
  },
  methods: {
    getOption(){
      dict_selectDictListByPid({pid:760}).then(res=>{
          this.userTypes = res.data;
      })
      
      AaumArchive_inspectorDropDown({}).then(res=>{
        this.checkerList = res.data;
      })
    },
    getTemplate(val){
      aaumTask_showTempletByUserType({userType:val}).then(res=>{
        if(res.data){
          this.form.templateName = res.data.tplName
        }else{
          this.form.templateName = ''
        }
        this.$refs.ruleForm.validateField('templateName')
      })
      aaumTask_findUserTreeEcho({userType:this.form.userType}).then(res=>{
        this.dataTree = res.data.data;
        this.expandedKeys = res.data.defaultExpandedKeys
        this.form.userIds = []
        this.tableData = [];
        this.$refs.ruleForm.clearValidate('userIds')
      })
    },
    getDesc(id){
      this.$nextTick(function(){
          if(this.titleStatus == 1){
            // aaumTask_findUserTreeEcho({}).then(res=>{
            //   this.dataTree = res.data.data;
            //   this.expandedKeys = res.data.defaultExpandedKeys
            //   if(this.$refs.ruleForm){
            //     this.$refs.ruleForm.resetFields()
            //   }
            // })
        }else{
            aaumTask_selectTaskById({taskId:id}).then(res=>{
            
            if( this.titleStatus == 2){
              this.form.userType = res.data.userType.toString();
              this.form.templateName = res.data.templateName;
              aaumTask_findUserTreeEcho({taskId:res.data.id,userType:res.data.userType}).then(resdata=>{
                this.dataTree = resdata.data.data;
                this.checkedKeys = resdata.data.defaultCheckedKeys
                this.expandedKeys = resdata.data.defaultExpandedKeys
              })
              this.form.inspectorIds = res.data.inspectorIds
              this.times = [res.data.startTime,res.data.endTime]
              this.form.startTime = res.data.startTime;
              this.form.endTime = res.data.endTime;
              this.form.id = res.data.id;
              this.form.userIds = res.data.userIds
            }else{
              this.form = res.data;
               this.form.startTime = this.formetTime(res.data.startTime);
              this.form.endTime = this.formetTime(res.data.endTime);
            }
            
            aaumTask_findAddrByAaumUsers(res.data.userIds).then(resList=>{
              this.tableData = resList.data
            })
          })
     
     }
      })
    },
    formetTime(time){
    let date = new Date(time);    

        var fullYear = date.getFullYear();    

        var month = date.getMonth() + 1;    

        var _date = date.getDate();    

        var hours = date.getHours();    

        var minutes = date.getMinutes();  

         var seconds = date.getSeconds();  

        if(month < 10){month = '0'+month}    

        if(_date < 10){_date = '0'+_date}  

         

        let Str=fullYear + '/' + month + '/' + _date     

        return Str

       },
    setDate(val){
      this.form.startTime = this.times[0]
      this.form.endTime = this.times[1]
    },
    watchUser(row) {
      this.userDialog = true;
      this.addrId = row.addrId
      this.loadList(1)
    },
    handleCheckChange(data, checked, indeterminate) {
      let list = this.$refs.dataTree.getCheckedNodes()
      this.tableDataNum = {}
      this.form.userIds = []
     list.forEach(el=>{
      if(el.type==5){
        if(!this.tableDataNum[el.pid]){
          this.tableDataNum[el.pid] = {userCount:1,addrName:el.addrName}
        }else{
          this.tableDataNum[el.pid].userCount = parseInt(this.tableDataNum[el.pid].userCount) +1
        }
        this.form.userIds.push(el.trueId.toString())
      }
     })
     this.tableData = Object.values(this.tableDataNum) 
    },
    handleNodeClick(data) {
      console.log(data);
    },
    confirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if(this.form.templateName==''){
            this.$message.error("请先添加模板")
            return
          }
          aaumTask_taskInsertOrUpdate(this.form).then(res=>{
            if(res.code=='200'){
              this.$message.success('保存成功')
              this.close()
            }else{
              this.$message.error(this.msg)
            }
          })
        }
      })
    },
    close() {
       this.form = {userIds:[],inspectorIds:[],startTime:'',templateName:'',userType:''}
       if(this.$refs.ruleForm){
        this.$refs.ruleForm.resetFields()
       }
       this.tableData = [];
       this.checkedKeys=[];
       this.expandedKeys=[];
       this.times=[];
      this.$emit("close");
    },
    closeUser(){
      this.tableDataUser = [];
    },
    /**
     * 更换条数
     */
    handleSizeChange(val){
      this.search.current = 1
      this.search.size = val
      this.loadList()
    },
    /**
     * 更换页码
     */
    handleCurrentChange(val){
      this.search.current = val
      this.loadList()
    },
    /**
     * 获取列表数据
     */
    loadList(page){
      if(page){
        this.search.current = 1
      }
      this.search.addrId = this.addrId;
      this.search.taskId = this.form.id;
      aaumTask_findUsersByTaskIdAndAddrId(this.search).then(res=>{
        this.tableDataUser = res.data.records
        this.total = res.data.total
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.map-row {
  position: relative;
  .btn-draw {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
.page {
  width: 99%;
  //   height: 100%;
  margin: 10px;

  overflow: auto;
  background: #fff;
  padding: 0 15px 0 15px ;
  box-sizing: border-box;
  ::v-deep {
    // .el-form-item {
      // margin-bottom: 0;
    // }
    .colsite {
      margin-bottom: 20px;
    }
  }
  .create {
    width: 100%;
    height: 50px;
    // background-color: burlywood;
    display: flex;
    flex-direction: row;
    align-items: center;
    .create_size {
      font-size: 20px;
      font-weight: 500;
      color: #252700;
      margin-left: 20px;
    }
  }
  .box1 {
    width: 96%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .mapLeft {
      width: 46%;
    }
    .tableRight {
      width: 100%;
      margin-top: 5px;
      margin-right: 25px;
      .btn {
        width: 210px;
        background: #0f74d5;
        border-radius: 4px;
        float: right;
        margin-right: 28%;
      }
      .title {
        padding: 10px;
        margin-bottom: 20px;
      }
      .row {
        padding: 12px 30px 0 30px;
      }
    }
  }
}
.img {
  height: 12px;
  border: 1px solid #3f74d5;
  margin-top: 6px;
  margin-right: 5px;
}
.titleName {
  font-size: 18px;
  color: #252733;
  padding-bottom: 15px;
}
.addBtn {
  margin: 0 10px;
}

.informFlex {
  border: 1px solid #dcdfe6;
  display: flex;
  justify-content: space-between;
  //   width: 80%;
  height: 400px;
  margin-top: 12px;
  border-radius: 4px;
  overflow: auto;
  ::v-deep .el-tree {
    // width: 30%;
    padding: 20px;
  }
  .table-contain {
    border-left: 1px solid #dcdfe6;
    width: 100%;
    padding: 10px 20px;
    span {
      font-size: 16px;
      color: #303133;
    }
  }
}
::v-deep .el-form-item {
  margin: 12px 0;
}

.watchForm {
  width: 70%;
  .el-form-item__label {
    color: #909399;
  }
  .el-form-item__content {
    color: #303133;
  }
}
</style>
